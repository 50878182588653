let rootUrl;
let apiUrl;
let usUrl;
let serverUrl;
let practiceUrl;
let stripeKey;
let ENV;
let sentryDsn;
let marketingUrl;
let therapyUrl;
let englishUrl;
let frenchUrl;
let amplitudeApiKey;
let mailchimpUrl;
let bbdEap;
let bbdBap;

const hostname = window && window.location && window.location.hostname;
const subdomain = hostname.split('.')[0];

if (hostname === 'localhost') {
  ENV = 'DEVELOPMENT';
  rootUrl = 'http://localhost:2020';
  apiUrl = 'http://localhost:3000/api/v2';
  usUrl = 'http://localhost:3000/api/v2';
  serverUrl = 'http://localhost:3000';
  practiceUrl = 'http://localhost:8080';
  marketingUrl = 'http://localhost:8000';
  therapyUrl = 'http://localhost:10101';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  englishUrl = 'http://localhost:2020';
  frenchUrl = 'http://localhost:2020';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=c70d23b332';
  bbdEap = false;
  bbdBap = false;
} else if (
  hostname === 'pcpeopleconnect.com' ||
  hostname === 'pcpeoplecare.com' ||
  hostname === 'peoplevousconnecte.com' ||
  hostname === 'lessoinspeople.com' ||
  subdomain === 'bbdcarepath-bap' ||
  subdomain === 'bbdeap' ||
  subdomain === 'bbdcarepath-pap' ||
  subdomain === 'bbdpae'
) {
  ENV = 'PRODUCTION';
  rootUrl =
    hostname.includes('pcpeopleconnect.com') || hostname.includes('pcpeoplecare.com')
      ? 'https://pcpeopleconnect.com'
      : 'https://peoplevousconnecte.com';
  apiUrl = 'https://api.inkblotpractice.com/api/v2';
  usUrl = 'https://medapi.inkblottherapy.com/api/v2';
  serverUrl = 'https://api.inkblotpractice.com';
  practiceUrl = 'https://app.inkblotpractice.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  marketingUrl = 'https://ink.inkblottherapy.com';
  therapyUrl = 'https://app.inkblottherapy.com';
  sentryDsn = 'https://d52a9ea29fb943ff8b937c8cee7aa3fd@sentry.io/1461513';
  englishUrl = 'https://pcpeopleconnect.com';
  frenchUrl = 'https://peoplevousconnecte.com';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=c70d23b332';
  bbdEap = subdomain === 'bbdeap' || subdomain === 'bbdpae';
  bbdBap = subdomain === 'bbdcarepath-bap' || subdomain === 'bbdcarepath-pap';
} else if (
  hostname === 'staging.pcpeopleconnect.com' ||
  hostname === 'staging.peoplevousconnecte.com' ||
  subdomain === 'bbdcarepath-bap-stg' ||
  subdomain === 'bbdeap-stg'
) {
  ENV = 'STAGING';
  rootUrl =
    hostname.includes('pcpeopleconnect.com') || hostname.includes('pcpeoplecare.com')
      ? 'https://staging.pcpeopleconnect.com'
      : 'https://staging.peoplevousconnecte.com';
  apiUrl = 'https://api.inkblottherapy.com/api/v2';
  usUrl = 'https://api.inkblottherapy.com/api/v2';
  serverUrl = 'https://api.inkblottherapy.com';
  practiceUrl = 'https://staging.inkblotpractice.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  marketingUrl = 'https://staging-marketing.inkblottherapy.com';
  therapyUrl = 'https://staging.inkblottherapy.com';
  sentryDsn = 'https://d52a9ea29fb943ff8b937c8cee7aa3fd@sentry.io/1461513';
  englishUrl = 'https://staging.pcpeopleconnect.com';
  frenchUrl = 'https://staging.peoplevousconnecte.com';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=c70d23b332';
  bbdEap = subdomain === 'bbdeap-stg';
  bbdBap = subdomain === 'bbdcarepath-bap-stg';
} else if (hostname === 'us.pcpeopleconnect.com') {
  ENV = 'US_PRODUCTION';
  rootUrl = 'https://us.pcpeopleconnect.com';
  apiUrl = 'https://medapi.inkblottherapy.com/api/v2';
  usUrl = 'https://medapi.inkblottherapy.com/api/v2';
  serverUrl = 'https://medapi.inkblottherapy.com';
  practiceUrl = 'https://portal.inkblotpractice.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  marketingUrl = 'https://us.inkblottherapy.com';
  therapyUrl = 'https://portal.inkblottherapy.com';
  sentryDsn = 'https://d52a9ea29fb943ff8b937c8cee7aa3fd@sentry.io/1461513';
  englishUrl = 'https://us.pcpeopleconnect.com';
  frenchUrl = 'https://peoplevousconnecte.com';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=c70d23b332';
}

export const ROOT_URL = `${rootUrl}`;
export const API_URL = `${apiUrl}`;
export const US_URL = `${usUrl}`;
export const SERVER_URL = `${serverUrl}`;
export const PRACTICE_URL = `${practiceUrl}`;
export const STRIPE_KEY = `${stripeKey}`;
export const ENVIRONMENT = `${ENV}`;
export const SENTRY_DSN = sentryDsn;
export const MARKETING_URL = marketingUrl;
export const THERAPY_URL = therapyUrl;
export const ENGLISH_URL = englishUrl;
export const FRENCH_URL = frenchUrl;
export const AMPLITUDE_API_KEY = amplitudeApiKey;
export const MAILCHIMP_URL = mailchimpUrl;
export const BBDEAP = bbdEap;
export const BBDBAP = bbdBap;
export const SUBDOMAIN = subdomain;
export const HOSTNAME = hostname;
