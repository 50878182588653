/**
 *
 * Business Assistance
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import PortalLayout from 'components/PortalLayout';
import bbdLogoFr from 'images/pcCarePathBapFr.png';
import bbdLogo from 'images/pcCarePathBap.png';
import { fetchUser } from '../App/actions';
import { trackUserClick } from '../EmployeeResources/actions';
import Card from './Card';
import messages from './messages';
import reducer from '../EmployeeResources/reducer';
import saga from '../EmployeeResources/saga';

// eslint-disable-next-line react/prefer-stateless-function
class BusinessAssistance extends Component {
  render() {
    const { intl } = this.props;
    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.title)}</title>
          <meta name="description" content="Description of Health Navigation" />
        </Helmet>
        <PortalLayout activeTab="business_assistance">
          <Card>
            <FormattedHTMLMessage tagName="h1" {...messages.title} />
            <FormattedHTMLMessage tagName="h3" {...messages.subtitle} />
            <FormattedHTMLMessage tagName="p" {...messages.body1} />
            <FormattedHTMLMessage tagName="p" {...messages.body2} />
            <FormattedHTMLMessage tagName="p" {...messages.body3} />
            <FormattedHTMLMessage tagName="p" {...messages.body4} />
            <FormattedHTMLMessage tagName="p" {...messages.body5} />
            <FormattedHTMLMessage tagName="p" {...messages.body6} />
            <FormattedHTMLMessage tagName="p" {...messages.body7} />
            <ul>
              <li>
                <FormattedHTMLMessage tagName="p" {...messages.body8} />
                <FormattedHTMLMessage tagName="p" {...messages.body9} />
              </li>
              <li>
                <FormattedHTMLMessage tagName="p" {...messages.body10} />
                <FormattedHTMLMessage tagName="p" {...messages.body11} />
              </li>
            </ul>
            <FormattedHTMLMessage tagName="p" {...messages.body12} />
            <FormattedHTMLMessage tagName="p" {...messages.body13} />
            <FormattedHTMLMessage tagName="p" {...messages.body14} />
            <FormattedHTMLMessage tagName="p" {...messages.body15} />
            <FormattedHTMLMessage tagName="p" {...messages.body16} />
            <ul>
              <li>
                <FormattedHTMLMessage tagName="p" {...messages.body17} />
              </li>
              <li>
                <FormattedHTMLMessage tagName="p" {...messages.body18} />
              </li>
            </ul>
            <FormattedHTMLMessage tagName="p" {...messages.body19} />
            <FormattedHTMLMessage tagName="p" {...messages.body20} />
            <FormattedHTMLMessage tagName="p" {...messages.body21} />
            <FormattedHTMLMessage tagName="p" {...messages.body22} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <img
                src={intl.locale === 'en' ? bbdLogo : bbdLogoFr}
                alt="bbd bap logo"
                style={{ width: '250px' }}
              />
            </div>
          </Card>
        </PortalLayout>
      </div>
    );
  }
}

BusinessAssistance.propTypes = {
  intl: intlShape,
  trackUserClick: PropTypes.func.isRequired,
  fetchUser: PropTypes.func,
  copmany: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    trackUserClick: values => dispatch(trackUserClick(values)),
    fetchUser: callback => dispatch(fetchUser(callback)),
  };
}

function mapStateToProps(state) {
  // eslint-disable-next-line prefer-destructuring
  const company = state.toJS().app.user.data.company;
  const status = state.toJS().app.user.data.people_care;
  return { company, status };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(injectIntl(BusinessAssistance));
