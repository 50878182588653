/*
 * CriticalIncidentServices Messages
 *
 * This contains all the text for the CriticalIncidentServices container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.FinancialLegal';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Financial and legal advisory services',
  },
  body1: {
    id: `${scope}.body1`,
    defaultMessage:
      'People Connect Business Assistance Program (BAP) offers secure and confidential telephonic consultations with a professional matched according to your needs.',
  },
  body2: {
    id: `${scope}.body2`,
    defaultMessage:
      'Through People Connect BAP, business owners will have access to:',
  },
  body3: {
    id: `${scope}.body3`,
    defaultMessage: 'Legal advisory services',
  },
  body4: {
    id: `${scope}.body4`,
    defaultMessage:
      'Consultations with highly qualified attorneys are typically 30 minutes in length and include common corporate law, labour/employment law, real estate and family law questions.',
  },
  body5: {
    id: `${scope}.body5`,
    defaultMessage: 'Financial advisory services',
  },
  body6: {
    id: `${scope}.body6`,
    defaultMessage:
      'Obtain answers and recommendations to solve business challenges, make informed decisions, understand tax implications, and better manage company finances. ',
  },
  body7: {
    id: `${scope}.body7`,
    defaultMessage:
      'Advisory services are unlimited and can be accessed as often as required.',
  },
  body8: {
    id: `${scope}.body8`,
    defaultMessage:
      'Consultations can be arranged Monday – Friday during the following hours:',
  },
  body9: {
    id: `${scope}.body9`,
    defaultMessage: 'Financial: 8am - 9pm EST',
  },
  body10: {
    id: `${scope}.body10`,
    defaultMessage: 'Legal: 9am - 8pm EST',
  },
  body11: {
    id: `${scope}.body11`,
    defaultMessage:
      'To arrange a virtual consultation, select <b>Additional Services</b> at the top of the dashboard and choose the service you would like to access, then click next. Select the category reason for your request and then click next.',
  },
  body12: {
    id: `${scope}.body12`,
    defaultMessage:
      '<b>Financial:</b> Enter your location and contact information and select two timeframes that you could be available for a phone call. Then select submit. A financial services representative will contact you directly to schedule your appointment.',
  },
  body13: {
    id: `${scope}.body13`,
    defaultMessage:
      '<b>Legal:</b> Call the hotline number that appears and use the consultation number provided to initiate your request.',
  },
});
